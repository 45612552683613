import React from 'react'

const cardContainer = 'w-full mt-6'
const card = 'border rounded'
const titleContainer = 'bg-gray-200 px-4 pt-4 pb-1'
const pStyle = 'text-gray-900 mb-3'
const labelStyle = 'sm:w-full w-1/3 text-xs text-gray-800 mb-3'
const dataStyle = 'font-bold text-2xl'
const h2Style = 'text-gray-900 text-xl font-semibold leading-none mb-3'
const listStyle = 'list-disc p-4'
const listItem = 'ml-6 mb-2 text-gray-900'

const PageAboutSection = () => (
  <div>
    <div className='flex flex-col w-full bg-red-700 items-center font-body justify-center px-4 py-8 mx-auto'>
      <h1 className='font-display text-4xl text-white text-center'>About Us</h1>
    </div>

    <div className='px-24 py-12 sm:px-4 md:px-8 sm:py-12'>
      <div className='sm:w-full w-11/12 xl:w-6/12 mx-auto mb-12'>
        <p className={pStyle}>South Bay Fire, Inc. has been family owned and operated since 1954, serving the Greater Los Angeles area. Founded by well respected World War II veteran, and City of Manhattan Beach fire captain, Howard A. Freeman, we take pride in providing quality fire protection products. As an authorized distributor of Amerex fire equipment, we offer a wide variety of fire extinguishers and accessories suitable for virtually any application.</p>

        <p className={pStyle}>At South Bay Fire, Inc., we are committed to protecting lives and property. No matter how small or large the job, our highly trained team does it all. Contact us today so we can help ensure your environment is fire safe.</p>

        <div className={cardContainer}>
          <div className={card}>
            <div className={titleContainer}>
              <h2 className={h2Style}>Core Data</h2>
            </div>

            <div className='flex flex-wrap p-4'>
              <p className={labelStyle}>DUNS<br/> <span className={dataStyle}>02-829-8131</span></p>
              <p className={labelStyle}>CAGE<br/> <span className={dataStyle}>7H025</span></p>
              <p className={labelStyle}>NAICS<br/> <span className={dataStyle}>922160</span></p>
              <p className={labelStyle}>EMPLOYEES<br/> <span className={dataStyle}>10</span></p>
              <p className={labelStyle}>GEO<br/> <span className={dataStyle}>CA, AZ, HI, NV</span></p>
              <p className={labelStyle}>INSURANCE<br/> <span className={dataStyle}>$1 MILLION</span></p>
            </div>
          </div>
        </div>

        <div className={cardContainer}>
          <div className={card}>
            <div className={titleContainer}>
              <h2 className={h2Style}>Core Competencies</h2>
            </div>

            <ul className={listStyle}>
              <li className={listItem}>AMEREX – Made in the USA Fire Protection Equipment</li>
              <li className={listItem}>Innovative fire-fighting products for all hazards</li>
              <li className={listItem}>Both “compliant” (code required), and “specialty” types of fire extinguishers</li>
            </ul>
          </div>
        </div>

        <div className={cardContainer}>
          <div className={card}>
            <div className={titleContainer}>
              <h2 className={h2Style}>Differentiators</h2>
            </div>

            <ul className={listStyle}>
              <li className={listItem}>Quick, efficient delivery process</li>
              <li className={listItem}>Made in the U.S.A. products</li>
              <li className={listItem}>Knowledgeable staff</li>
            </ul>
          </div>
        </div>

        <div className={cardContainer}>
          <div className={card}>
            <div className={titleContainer}>
              <h2 className={h2Style}>Available Resources</h2>
            </div>

            <ul className={listStyle}>
              <li className={listItem}>6 vehicle fleet</li>
              <li className={listItem}>Providing service to the Greater Los Angeles area</li>
              <li className={listItem}>Licensing by: CSFM, CSLB, and many local city licenses</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PageAboutSection