import React from "react"

import HomeContainer from "../components/containers/HomeContainer"
import HeadData from "../data/HeadData"
import PageHeroHeader from '../components/headers/PageHeroHeader'
import HeaderNav from '../components/navigation/HeaderNav'
import PageAboutSection from "../components/sections/PageAboutSection"

export default () => (
  <HomeContainer>
    <HeadData title='About Us' />

    <PageHeroHeader>
      <HeaderNav isHome />
    </PageHeroHeader>

    <PageAboutSection />
  </HomeContainer>
)